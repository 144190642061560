import React, { createRef, useEffect, useState } from 'react';
import './GwFlowFaceCaptureIdrnd.css';

import { GwFlowFaceCaptureProvider } from './GwFlowFaceCapture';
import imageCompression from 'browser-image-compression';
import 'idlive-face-capture-web-development';
import classNames from 'classnames';
import { Spin } from 'antd';
import {
    IdrndExtractionResult,
    GwFlowFaceCaptureProviderResult,
} from 'gw-api/dist/types';
import blobToBase64 from 'gw-api/dist/helpers/blobToBase64';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'camera-component': any;
            'idlive-face-capture': any;
        }
    }
}

export default GwFlowFaceCaptureIdrnd;

export type FacephiExtractionEvent = {
    detail: IdrndExtractionResult[];
};

export type GwFlowFaceCaptureIdrndProps = GwFlowFaceCaptureProvider & {};

function GwFlowFaceCaptureIdrnd({
    onCancel,
    onSuccess,
    onError,
    locale,
}: GwFlowFaceCaptureIdrndProps) {
    const widgetRef = createRef();

    const [loading, setLoading] = useState<boolean>(true);
    const [opened, setOpened] = useState<boolean>(false);

    const handleExceptionCaptured = (exceptionResult: any) => {
        onError?.(new Error(`[IDRND]: ${exceptionResult.detail[0]?.message}`));
    };
    const handleExtractionFinished = async (
        extractionResult: FacephiExtractionEvent
    ) => {
        // @ts-ignore/
        widgetRef.current?.closeCamera();
        const options = {
            maxSizeMB: 3,
            fileType: 'png',
            useWebWorker: true,
        };
        const compressedFile = await imageCompression(
            extractionResult.detail[0]?.photo,
            options
        );
        const compresssedBase64DataUrl =
            await imageCompression.getDataUrlFromFile(compressedFile);

        const encryptedFileBase64 = await blobToBase64(
            extractionResult.detail[0]?.encryptedFile
        );

        const extraction: GwFlowFaceCaptureProviderResult = {
            bestImage: compresssedBase64DataUrl,
            encryptedFile: encryptedFileBase64,
            // rawFaceCapture: extractionResult.detail,
        };
        onSuccess?.(extraction);
    };

    const handleInitialize = async () => {
        widgetRef.current.openCamera();
        setLoading(true);
    };
    const handleOpen = async () => {
        setOpened(true);
        setLoading(false);
    };
    const handleClose = async () => {
        setOpened(false);
    };

    useEffect(() => {
        widgetRef.current = document.querySelector('idlive-face-capture');
        widgetRef.current.addEventListener('initialize', handleInitialize);
        widgetRef.current.addEventListener('open', handleOpen);
        widgetRef.current.addEventListener('close', handleClose);
        widgetRef.current.addEventListener('capture', handleExtractionFinished);
        widgetRef.current.addEventListener('error', handleExceptionCaptured);
        return () => {
            if (widgetRef.current) {
                widgetRef.current.removeEventListener(
                    'capture',
                    handleExtractionFinished
                );
                widgetRef.current.removeEventListener(
                    'capture',
                    handleExtractionFinished
                );
                widgetRef.current.removeEventListener(
                    'error',
                    handleExceptionCaptured
                );
            }
        };
    }, []);

    return (
        <div className="GwFlowFaceCaptureIdrnd">
            <div className="GwFlowFaceCaptureIdrnd-cameraWrapper">
                <div
                    className={classNames(
                        'GwFlowFaceCaptureIdrnd-maskWrapper',
                        {
                            'GwFlowFaceCaptureIdrnd-maskWrapper--loading':
                                loading,
                        }
                    )}
                >
                    <div
                        className={classNames(
                            'GwFlowFaceCaptureIdrnd-maskTarget',
                            {
                                'GwFlowFaceCaptureIdrnd-maskTarget--opened':
                                    opened,
                            }
                        )}
                    >
                        <idlive-face-capture
                            class="GwFlowFaceCaptureIdrnd-camera"
                            mask_hidden="true"
                            disable_control_panel="true"
                            stop_after_capturing="true"
                            face_detection="true"
                            debug="false"
                            logo_style='{"opacity": "0", "max-width": "300px"}'
                            model_path="/models/"
                        ></idlive-face-capture>
                    </div>
                </div>
                <Spin
                    className="GwFlowFaceCaptureIdrnd-loader"
                    spinning={loading}
                />
            </div>
        </div>
    );
}
